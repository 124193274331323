import React, { useState, useEffect } from 'react'
import AppShell from '../../components/AppShell/AppShell'
import CONS from '../../common/Constants'
import Cruce from './Cruce'
import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import loader from '../../assets/img/loading.gif'
import notfound from '../../assets/img/404-2.gif'
import { Link } from 'react-router-dom'

export default function SeleccionarCruce() {
    const [state, setState] = useState(JSON.parse(localStorage.getItem('estado')))
    const [show, setShow] = useState(false)
    const history = useHistory()

    useEffect(() => {
        const fetchCruces = async () => {
            if (state && !state.busqueda.resultado && state.busqueda) {
                try {
                    const tramo_natales = [41, 46, 51, 56].includes(parseInt(state.busqueda[0].origen));
                    console.log(`Tramo natales:${tramo_natales}`);

                    console.log('URL de API CRUCES TRAMOS:', process.env.REACT_APP_API_URL_CRUCES_TRAMOS)
                    const apiUrlCruceTramos = process.env.REACT_APP_API_URL_CRUCES_TRAMOS;

                    // Crear array de promesas para todas las búsquedas
                    const promises = state.busqueda.map(async (busqueda) => {
                        const split = busqueda.fecha.split('-')
                        const fecha = `${split[2]}-${split[1]}-${split[0]}`

                        const postData = {
                            fecha: fecha,
                            origen: parseInt(busqueda.origen),
                            destino: parseInt(busqueda.destino)
                        };

                        console.log('que trae postData', postData)

                        const response = await fetch(apiUrlCruceTramos, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(postData)
                        });

                        return response.json();
                    });

                    // Esperar a que todas las promesas se resuelvan
                    const results = await Promise.all(promises);

                    if (tramo_natales) {
                        // Filtrar solo los primeros elementos válidos de cada resultado
                        const cruces = results
                            .map(data => data[0])
                            .filter(cruce => cruce != null);

                        // Actualizar estado una sola vez con todos los resultados
                        const newState = {
                            ...state,
                            busqueda: {
                                ...state.busqueda,
                                resultado: cruces
                            },
                            cruceSeleccionado: cruces
                        };

                        setState(newState);
                        localStorage.setItem('estado', JSON.stringify(newState));
                        localStorage.setItem('tramo_natales', JSON.stringify(tramo_natales));

                        if (cruces.length > 0) {
                            return history.push('/escaner');
                        }
                    } else {
                        // Para casos no tramo_natales, usar el primer resultado
                        setState({
                            ...state,
                            busqueda: {
                                ...state.busqueda,
                                resultado: results[0]
                            }
                        });
                    }

                    setShow(true);
                } catch (error) {
                    console.error('Error al obtener cruces:', error);
                    setShow(true);
                }
            }
        };

        fetchCruces();
    }, []) // Solo se ejecuta una vez al montar el componente

    return (
        <AppShell titulo="Seleccione el cruce">
            {state.busqueda.resultado &&
                state.busqueda.resultado.length === 0 && 
                <div className="w-11/12 mx-auto pt-6 text-center">
                    <h1 className="text-7xl font-black italic text-blue-900">404</h1>
                    <small className="block w-full text-center text-yellow-600 text-sm px-4 my-2">No se encontraron resultados para su busqueda</small>
                    <div className="w-full text-center">
                        <img src={notfound} alt="404" className="my-4 mx-auto" />
                    </div>
                    <Link to="/configurar-tramo">
                        <button className="w-full block bg-yellow-600 text-white shadow-xl rounded uppercase font-semibold py-3 border-t border-l border-yellow-700">Cambiar fecha o tramo</button>
                    </Link>
                </div>
            }

            <div className="w-full mx-auto relative">
                <Transition
                    show={!show}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-full h-full bg-gray-white absolute z-20">
                        <img src={loader} alt="cargando..." className='px-12 mb-2 pt-10 mx-auto' />
                        <div className="text-center w-full text-yellow-600 uppercase font-black text-sm">Cargando resultados...</div>
                    </div>
                </Transition>

                <Transition
                    show={show}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="transition bg-gray-700 shadow overflow-hidden relative z-10">
                        <ul className="divide-y divide-gray-100">
                            {state.busqueda.resultado &&
                                state.busqueda.resultado.length > 0 &&
                                Object.keys(state.busqueda.resultado).map(item => {
                                    return (
                                        <li key={state.busqueda.resultado[item].id_cruce}>
                                            <Cruce
                                                idCruce={state.busqueda.resultado[item].id_cruce}
                                                idTramo={state.busqueda.resultado[item].id_tramo}
                                                tramo={state.busqueda.resultado[item].nombre_tramo}
                                                cupoPasajero={state.busqueda.resultado[item].cupo_pasajeros_maximo}
                                                cupoVehiculo={state.busqueda.resultado[item].cupo_vehiculos_maximo}
                                                horarioPresentacion={state.busqueda.resultado[item].horario_presentacion}
                                                horarioCruce={state.busqueda.resultado[item].horario_cruce}
                                                nombreNave={state.busqueda.resultado[item].nombre_nave}
                                            />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Transition>
            </div>
        </AppShell>
    )
}